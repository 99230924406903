@import '../../main.scss';

.crossword-wrapper {
    box-sizing: border-box;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    * {
        font-family: Helvetica;
    }

    .crossword-row {
        display: flex;
        width: 100%;

        .crossword-square {
            position: relative;
            box-sizing: border-box;

            &:hover {
                cursor: default;
            }

            .corner-number {
                position: absolute;
                font-size: 1.5vh;
            }

            .square-content {
                position: absolute;
                font-size: 4vh;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }
}
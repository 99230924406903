@import '../../main.scss';

.article-card-wrapper {
    height: 100%;
    display: grid;

    .picture {
        justify-self: center;
    }

    .article-wrapper {
        text-align: left;

        .article-title {
            margin: 0em;
        }
    }

    .no-image {
        grid-column: 1 / span 2;
        padding: 0em;
    }
}

@import '../../main.scss';

.dropdown-wrapper {
    padding-right: 0.8em;

    .dropdown {
        position: relative;
        
    
        .dropdown-icon {
            line-height: 0em;
    
            &:hover {
                cursor: pointer;
                color: $hover-color;
            }
        }
    
        .dropdown-choices {
            position: absolute;
            z-index: 1;
            float: right;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            right: 0%;
    
            background-color: white;
            border: 1px solid black;
        }
    }
}


@import '../../main.scss';

.page-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;
    align-content: start;
    
    overflow-y: scroll;

    .page-header {
        grid-row: 1;
    }

    .page-content-wrapper {
        grid-row: 2;
        height: 100%;
        display: flex;
        justify-content: center;
    }
}
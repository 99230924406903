@import '../../main.scss';

.experiences-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: min-content 1fr;

    .experiences-title {
        line-height: 0em;
    }
    
    .experiences-content {
        display: grid;
        height: 100%;
    }
}
@import '../../main.scss';

.projects-page-content-wrapper {
    width: 100%;
    padding: 5vh;
    
    * {
        transition: none;
    }

    .gyoza {
        grid-row: 2 / span 3;
        grid-column: 1;
    }

    .admiral {
        grid-row: 1;
        grid-column: 1 / span 3;
    }

    .two-cents {
        grid-row: 2;
        grid-column: 2 / span 2;
    }

    .cube {
        grid-row: 3 / span 2;
        grid-column: 2;
    }

    .rhyme-time {
        grid-row: 3 / span 2;
        grid-column: 3;
    }

    .text-editor {
        grid-row: 5;
        grid-column: 1 / span 3;
    }
}
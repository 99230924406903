@import '../../main.scss';

.button-wrapper {
    width: 100%;
    text-align: center;
    font-size: 2vh;

    &:hover {
        cursor: pointer;
        color: $hover-color;
    }
}

.nav-button-active {
    text-decoration: underline;
}
@import '../../main.scss';

.home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    * {
        transition: none;
    }

    .icon-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-top: 1em;
    }
    
    .icon {
        color: black;
        padding-left: 0px;
        padding-right: 0px;

        &:hover {
            cursor: pointer;
            color: $hover-color;
        }
    }
}

@import '../../main.scss';

.header-wrapper {
    width: 100%;
    height: 100%;

    .header-text {
        font-family: 'old-english';
        font-size: 5vh;
        text-align: center;
    }
    
    .bar-wrapper {
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;

        .left-nav-buttons {
            grid-column: 1;
            display: flex;
            justify-content: flex-start;
        }

        .date-wrapper {
            grid-column: 2;
            font-size: 2vh;
            justify-self: center;
        }
        
        .right-nav-buttons {
            grid-column: 3;
            display: flex;
            justify-content: flex-end;
        }

        .nav-button {
            padding: 0em 1em;
        }

        .button-in-dropdown {
            padding: 0.5em;
        }
    }
}

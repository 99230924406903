.skills-wrapper {
    
    .skill-section {
        padding-bottom: 0.5em;
    }

    .skill-icon {
        height: 108px;
        width: 108px;
        padding: 0.5em;
    }
}
$hover-color: rgb(100, 100, 100);
$light-hover-color: rgb(216, 216, 216);

@font-face {
    font-family: 'old-english';
    src: url('./Assets/Fonts/OldEnglishFive.ttf');
}

@font-face {
    font-family: 'lora-semi-bold';
    src: url('./Assets/Fonts/Lora-SemiBold.ttf');
}

* {
    box-sizing: border-box;
    transition: all .25s ease;
    font-family: Georgia;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
    }
}

body, html, .page {
    min-height: 100vh;
    min-width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    margin: 0em;
    padding: 0em;
    overflow-x: hidden;
}

.title {
    font-family: 'lora-semi-bold';
}

.thin-horz-line {
    border-top: 1.5px solid black;
    width: 100%;
    line-height: 0em;
}

.thick-horz-line {
    border-top: 2.5px solid black;
    width: 100%;
    line-height: 0em;
}

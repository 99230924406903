@import '../../main.scss';

.crossword-clues-wrapper {
    width: 100%;
    margin: 0em;
    overflow-y: scroll;

    .clues-title {
        font-size: 3vh;
        margin: 0em;
        margin-bottom: 0.5em;
    }

    .clues-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        .clue {
            margin-left: 1em;
            position: relative;
            margin-bottom: 1em;
            padding: 0.5em;

            &:hover {
                cursor: pointer;
                background-color: $light-hover-color;
            }

            .clue-number {
                position: absolute;
                left: -1em;
                font-weight: bold;
            }

            .clue-dates {
                font-weight: bold;
            }

            .clue-title {
                font-style: italic;
                padding-bottom: 0.5em;
            }
        }

        .active {
            background-color: $light-hover-color;
        }
    }
}